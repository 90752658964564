/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// Note: The listingFields come from listingFields asset nowadays by default.
//       To use this built-in configuration, you need to change the overwrite from configHelper.js
//       (E.g. use mergeDefaultTypesAndFieldsForDebugging func)

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  {
    key: 'productType',
    scope: 'public',
    includeForListingTypes: ['product-selling'],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: "clothing", label: "Clothing" },
      { option: "shoes", label: "Shoes" },
      { option: "bags", label: "Bags" },
      { option: "accessories", label: "Accessories" },
      { option: "jewelry", label: "Jewelry" },
      { option: "textiles", label: "Textiles" },
      { option: "other-items", label: "Other items" },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Product type',
      group: 'primary',
    },
    showConfig: {
      label: 'Product type',
      isDetail: true,
    },
    saveConfig: {
      label: 'Product type',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a product type.',
    },
  },
  {
    key: 'productGroup',
    scope: 'public',
    includeForListingTypes: ['product-selling'],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'women', label: 'Women' },
      { option: 'men', label: 'Men' },
      { option: 'kids', label: 'Kids' },
      { option: 'pets', label: 'Pets' },
      { option: 'home', label: 'Home' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Product group',
      group: 'primary',
    },
    showConfig: {
      label: 'Product group',
      isDetail: true,
    },
    saveConfig: {
      label: 'Product group',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a product group.',
    },
  },
  {
    key: 'storeLabels',
    scope: 'public',
    includeForListingTypes: ['product-selling'],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: "casual", label: "Casual" },
      { option: "formal", label: "Formal" },
      { option: "classic", label: "Classic" },
      { option: "evening", label: "Evening" },
      { option: "elegant", label: "Elegant" },
      { option: "timeless", label: "Timeless" },
      { option: "minimalist", label: "Minimalist" },
      { option: "trend-oriented", label: "Trend oriented" },
      { option: "modern", label: "Modern" },
      { option: "bohemian", label: "Bohemian" },
      { option: "retro", label: "Retro" },
      { option: "vintage", label: "Vintage" },
      { option: "streetwear", label: "Streetwear" },
      { option: "punk", label: "Punk" },
      { option: "ethnic", label: "Ethnic" },
      { option: "gothic", label: "Gothic" },
      { option: "fair-trade", label: "Fair-trade" },
      { option: "sustainable", label: "Sustainable" },
      { option: "artsy", label: "Artsy" },
      { option: "green", label: "Green" },
      { option: "activewear", label: "Activewear" },
      { option: "outdoor", label: "Outdoor" },
      { option: "sporty", label: "Sporty" },
      { option: "swimwear", label: "Swimwear" },
      { option: "bridal", label: "Bridal" },
      { option: "suits", label: "Suits" },
      { option: "tailoring", label: "Tailoring" },
      { option: "special-occasion", label: "Special occasion" },
      { option: "costumes", label: "Costumes" },
      { option: "lingerie", label: "Lingerie" },
      { option: "underwear", label: "Underwear" },
      { option: "nightwear", label: "Nightwear" },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Store Labels',
      group: 'primary',
    },
    showConfig: {
      label: 'Store Labels',
      isDetail: true,
    },
    saveConfig: {
      label: 'Store Labels',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a store label.',
    },
  },
  {
    key: 'sustainabilityFactors',
    scope: 'public',
    includeForListingTypes: ['brand-listing'],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: "altruistic", label: "Altruistic" },
      { option: "artisanal", label: "Artisanal" },
      { option: "carbon-reduction", label: "Carbon reduction" },
      { option: "charitable-donation", label: "Charitable donation" },
      { option: "co-product", label: "Co-product" },
      { option: "cruelty-free", label: "Cruelty-free" },
      { option: "environmentally-friendly", label: "Environmentally friendly" },
      { option: "ethical-leader", label: "Ethical leader" },
      { option: "ethically-made", label: "Ethically made" },
      { option: "experimental", label: "Experimental" },
      { option: "happy-worker", label: "Happy worker" },
      { option: "independent-brand", label: "Independent brand" },
      { option: "locally-sourced", label: "Locally sourced" },
      { option: "natural-materials", label: "Natural materials" },
      { option: "non-toxic-dyes", label: "Non-toxic dyes" },
      { option: "one-of-a-kind", label: "One of a kind" },
      { option: "organic", label: "Organic" },
      { option: "re-upcycled-materials", label: "Re/upcycled materials" },
      { option: "responsible-packaging", label: "Responsible packaging" },
      { option: "unique", label: "Unique" },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Sustainability Factors',
      group: 'primary',
    },
    showConfig: {
      label: 'Sustainability Factors',
      isDetail: true,
    },
    saveConfig: {
      label: 'Sustainability Factors',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a sustainability factor.',
    },
  },
  {
    key: 'displayType',
    scope: 'public',
    includeForListingTypes: ['product-selling'],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'clothingRack', label: 'Clothing racks' },
      { option: 'windowDisplay', label: 'Window displays' },
      { option: 'tableDisplay', label: 'Table displays' },
      { option: 'glassDisplay', label: 'Glass displays' },
      { option: 'mannequin', label: 'Mannequins' },
      { option: 'shelfDisplay', label: 'Shelf displays' },
      { option: 'countertop', label: 'Countertop' },
      { option: 'otherDisplay', label: 'Other displays' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      label: 'Display type',
      group: 'primary',
    },
    showConfig: {
      label: 'Display type',
      isDetail: true,
    },
    saveConfig: {
      label: 'Display type',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a display type.',
    },
  },
  {
    key: 'condition',
    scope: 'public',
    includeForListingTypes: ['product-selling'],
    schemaType: 'multi-enum',
    enumOptions: [
      // { option: 'neverWornedWithTag', label: 'Never worn with a tag' },
      // { option: 'neverWornedWithoutTag', label: 'Never worn without a tag' },
      // { option: 'excellent', label: 'Excellent' },
      // { option: 'goodWithNegligibleDefects', label: 'Good with negligible defects' },
      // { option: 'goodWithDefects', label: 'Good with defects' },
      { option: 'new', label: 'New' },
      { option: 'preloved', label: 'Preloved' },
    ],
    filterConfig: {
      filterType: 'SelectMultipleChipFilter',
      layoutType: 'column',
      indexForSearch: true,
      label: 'Condition',
      group: 'secondary',
    },
    showConfig: {
      label: 'Condition',
      isDetail: true,
    },
    saveConfig: {
      label: 'Condition',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a condition.',
    },
  },
  {
    key: 'department',
    scope: 'public',
    includeForListingTypes: ['product-selling'],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'men', label: 'Men' },
      { option: 'women', label: 'Women' },
      { option: 'kids', label: 'Kids' },
      { option: 'girls', label: 'Girls' },
      { option: 'boys', label: 'Boys' },
    ],
    filterConfig: {
      filterType: 'SelectMultipleChipFilter',
      layoutType: 'column',
      indexForSearch: true,
      label: 'Department',
      group: 'primary',
    },
    showConfig: {
      label: 'Department',
      isDetail: true,
    },
    saveConfig: {
      label: 'Department',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a department.',
    },
  },

  {
    key: 'category',
    scope: 'public',
    includeForListingTypes: ['product-selling'],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'justIn', label: 'Just In' },
      { option: 'women', label: 'Women' },
      { option: 'wedding', label: 'Wedding' },
      { option: 'designers', label: 'Designers' },

      { option: 'men', label: 'Men' },
      { option: 'kids', label: 'Kids' },
      { option: 'accessoriesDecorFavors', label: 'Accessories, Decor & Favors' },

    ],
    filterConfig: {
      filterType: 'SelectMultipleChipFilter',
      layoutType: 'column',
      indexForSearch: true,
      label: 'Type',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: true,
    },
    saveConfig: {
      label: 'Category',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a category',
    },
  },

  {
    key: 'subcategory',
    scope: 'public',
    includeForListingTypes: ['product-selling'],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'sherwani', label: 'Sherwani', category: 'men' },
      { option: 'kurtaSets', label: 'Kurta Sets', category: 'men' },
      { option: 'shirts', label: 'Shirts', category: 'men' },
      { option: 'bandhgala', label: 'Bandhgala', category: 'men' },
      { option: 'indoWestern', label: 'Indo-Western', category: 'men' },
      { option: 'daddyAndMe', label: 'Daddy & Me', category: 'men' },
      { option: 'costume', label: 'Costume', category: 'men' },
      { option: 'other', label: 'Other', category: 'men' },
      { option: 'saree', label: 'Sare', category: 'women' },
      { option: 'preStitchedDrapedSaree', label: 'Pre-Stitched/Draped Saree', category: 'women' },
      { option: 'blouse', label: 'Blouse', category: 'women' },
      { option: 'anarkali', label: 'Anarkali', category: 'women' },
      { option: 'lengha', label: 'Lengha', category: 'women' },
      { option: 'sharara', label: 'Sharara', category: 'women' },
      { option: 'kurtaTunicSet', label: 'Kurta/Tunic/Set', category: 'women' },
      { option: 'churidaarSalwarKameez', label: 'Churidaar/Salwar Kameez', category: 'women' },
      { option: 'gownIndoWestern', label: 'Gown/Indo-Western', category: 'women' },
      { option: 'mommyAndMe', label: 'Mommy & Me', category: 'women' },
      { option: 'maternity', label: 'Maternity', category: 'women' },
      { option: 'robeSleep', label: 'Robe/Sleep', category: 'women' },
      { option: 'costumes', label: 'Costumes', category: 'women' },
      { option: 'other', label: 'Other', category: 'women' },
      { option: 'boys', label: 'Boys', category: 'kids' }, //'Kurtas', 'Kurta Sets', 'Sherwani', 'WaistCoats', 'Shirts', 'Nehru Jackets/Suits', 'Daddy & Me', 'Other'
      { option: 'girls', label: 'Girls', category: 'kids' }, //'Sarees/Drape Sarees', 'Lehengas', 'Anarkalis/Kurta Sets', 'Kurtas/Tops', 'Shararas', 'Gowns/Dresses', 'Mommy & Me', 'Other'
      { option: 'jewelry', label: 'Jewelry', category: 'accessoriesDecorFavors' },
      { option: 'bags', label: 'Bags', category: 'accessoriesDecorFavors' },
      { option: 'belts', label: 'Belts', category: 'accessoriesDecorFavors' },
      { option: 'footwear', label: 'Footwear', category: 'accessoriesDecorFavors' },
      { option: 'shawlsScarves', label: 'Shawls/Scarves', category: 'accessoriesDecorFavors' },


      // make options for designers
      { option: 'Sabyasachi', label: 'Sabyasachi', category: 'designers' },
      { option: 'Seema Gujral', label: 'Seema Gujral', category: 'designers' },
      { option: 'Ritu Kumar', label: 'Ritu Kumar', category: 'designers' },
      { option: 'Anushree Reddy', label: 'Anushree Reddy', category: 'designers' },
      { option: 'Manish Malhotra', label: 'Manish Malhotra', category: 'designers' },
      { option: 'Papa Don\'t Preach', label: 'Papa Don\'t Preach', category: 'designers' },
      { option: 'Anamika Khanna', label: 'Anamika Khanna', category: 'designers' },
      { option: 'Chamee & Palak', label: 'Chamee & Palak', category: 'designers' },
      { option: 'House of Masaba', label: 'House of Masaba', category: 'designers' },
      { option: 'Label Anushree/Anushree Reddy', label: 'Label Anushree/Anushree Reddy', category: 'designers' },
      { option: 'Mahima Mahajan', label: 'Mahima Mahajan', category: 'designers' },
      { option: 'Paulmi & Harsh', label: 'Paulmi & Harsh', category: 'designers' },
      { option: 'Payal Singhal', label: 'Payal Singhal', category: 'designers' },
      { option: 'VVani by Vani Vaats', label: 'VVani by Vani Vaats', category: 'designers' },
    ],
  },



  {
    key: 'productType',
    scope: 'public',
    includeForListingTypes: ['product-selling'],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'outerwear', label: 'Outerwear' },
      { option: 'top', label: 'Top' },
      { option: 'bottom', label: 'Bottom' },
      { option: 'dress', label: 'Dress' },
      { option: 'jumpsuit', label: 'Jumpsuit' },
      { option: 'beachwear', label: 'Beachwear' },
      { option: 'sport', label: 'Sport' },
      { option: 'backpack', label: 'Backpack' },
      { option: 'beltBag', label: 'Belt bag' },
      { option: 'bucketBag', label: 'Bucket Bag' },
      { option: 'clutchBag', label: 'Clutch Bag' },
      { option: 'CrossBodyBag', label: 'Cross-body bag' },
      { option: 'travel', label: 'Travel' },
      { option: 'miniBag', label: 'Mini Bag' },
      { option: 'shoulderBag', label: 'Shoulder Bag' },
      { option: 'toteBag', label: 'Tote Bag' },
      { option: 'sandals', label: 'Sandals' },
      { option: 'espadrilles', label: 'Espadrilles' },
      { option: 'mulesClogs', label: 'Mules & Clogs' },
      { option: 'sneakers', label: 'Sneakers' },
      { option: 'ballerinas', label: 'Ballerinas' },
      { option: 'laceUps', label: 'Lace ups' },
      { option: 'ankleBoots', label: 'Ankle Boots' },
      { option: 'boots', label: 'Boots' },
      { option: 'pumps', label: 'Pumps' },
      { option: 'earrings', label: 'Earrings' },
      { option: 'necklace', label: 'Necklace' },
      { option: 'bracelet', label: 'Bracelet' },
      { option: 'bodyJewellery', label: 'Body Jewellery' },
      { option: 'ring', label: 'Ring' },
      { option: 'watch', label: 'Watch' },
      { option: 'pins', label: 'Pins' },
      { option: 'charm', label: 'Charm' },
      { option: 'sunglasses', label: 'Sunglasses' },
      { option: 'belts', label: 'Belts' },
      { option: 'wallets', label: 'Wallets' },
      { option: 'hats', label: 'Hats' },
      { option: 'scarve', label: 'Scarve' },
      { option: 'gloves', label: 'Gloves' },
      { option: 'hairAccessory', label: 'Hair Accessory' },
      { option: 'bagAccessory', label: 'Bag accessory' },
      { option: 'optical', label: 'Optical' },
      { option: 'watches', label: 'Watches' },
      { option: 'ankleBracelet', label: 'Ankle Bracelet' }
    ],

    filterConfig: {
      filterType: 'SelectMultipleFilterWithSearch',
      indexForSearch: true,
      label: 'Product Type',
      group: 'primary',
    },
    showConfig: {
      label: 'Product Type',
      isDetail: true,
    },
    saveConfig: {
      label: 'Product Type',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a product type',
    },
  },

  {
    key: 'productSubType',
    scope: 'public',
    includeForListingTypes: ['product-selling'],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'coat', label: 'Coat' },
      { option: 'tenchCoat', label: 'Trench coat' },
      { option: 'jacket', label: 'Jacket' },
      { option: 'pufferJacket', label: 'Puffer Jacket' },
      { option: 'leatherJacket', label: 'Leather jacket' },
      { option: 'vest', label: 'Vest' },
      { option: 'blazer', label: 'Blazer' },
      { option: 'cape', label: 'Cape' },
      { option: 'sweater', label: 'Sweater' },
      { option: 'cardigan', label: 'Cardigan' },
      { option: 'hoodie', label: 'Hoodie' },
      { option: 'sweatshirt', label: 'Sweatshirt' },
      { option: 'tshirt', label: 'T-shirt' },
      { option: 'blouse', label: 'Blouse' },
      { option: 'shirt', label: 'Shirt' },
      { option: 'camisole', label: 'Camisole' },
      { option: 'sportsBra', label: 'Sports bra' },
      { option: 'cropTop', label: 'Crop Top' },
      { option: 'skirt', label: 'Skirt' },
      { option: 'sorts', label: 'Shorts' },
      { option: 'trousers', label: 'Trousers' },
      { option: 'jeans', label: 'Jeans' },
      { option: 'leggings', label: 'Leggings' },
      { option: 'sweatpants', label: 'Sweatpants' },
      { option: 'shortDress', label: 'Short dress' },
      { option: 'midiDress', label: 'Midi dress' },
      { option: 'longDress', label: 'Long dress' },
      { option: 'shirtDress', label: 'Shirt dress' },
      { option: 'shortJumpsuit', label: 'Short jumpsuit' },
      { option: 'midiJumpsuit', label: 'Midi jumpsuit' },
      { option: 'longJumpsuit', label: 'Long jumpsuit' },
      { option: 'bikiniTop', label: 'Bikini top' },
      { option: 'bikiniBottom', label: 'Bikini bottom' },
      { option: 'bikiniSet', label: 'Bikini set' },
      { option: 'onePieceSwimsuit', label: 'One-piece swimsuit' },
      { option: 'shorts', label: 'Shorts' },
      { option: 'beachDress', label: 'Beach dress' },
      { option: 'coverUp', label: 'Cover up' },
      { option: 'kaftansCapesKimonos', label: 'Kaftans, Capes & Kimonos' },
      { option: 'sportsBra', label: 'Sports bra' },
      { option: 'leggings', label: 'Leggings' },
      { option: 'shorts', label: 'Shorts' },
      { option: 'top', label: 'Top' },
      { option: 'skirt', label: 'Skirt' },
      { option: 'sweatshirt', label: 'Sweatshirt' },
      { option: 'sweatpants', label: 'Sweatpants' },
      { option: 'hoodie', label: 'Hoodie' },
      { option: 'jacket', label: 'Jacket' }
    ],
    filterConfig: {
      filterType: 'SelectMultipleFilterWithSearch',
      indexForSearch: true,
      label: 'Product Sub-Type',
      group: 'primary',
    },
    showConfig: {
      label: 'Product Sub-Type',
      isDetail: true,
    },
    saveConfig: {
      label: 'Product Sub-Type',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a product subtype',
    },
  },

  {
    key: 'sizeType',
    scope: 'public',
    includeForListingTypes: ['product-selling'],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'int', label: 'INT' },
      { option: 'uk', label: 'UK' },
      { option: 'eu', label: 'EU' },
      { option: 'us', label: 'US' },
      { option: 'it', label: 'IT' },
    ],
    filterConfig: {
      filterType: 'SelectMultipleChipFilter',
      layoutType: 'row',
      indexForSearch: true,
      label: 'Size Type',
      group: 'primary',
    },
    showConfig: {
      label: 'Size Type',
      isDetail: true,
    },
    saveConfig: {
      label: 'Size Type',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a size type',
    },
  },

  {
    key: 'size',
    scope: 'public',
    includeForListingTypes: ['product-selling'],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'xs', label: 'XS' },
      { option: 's', label: 'S' },
      { option: 'm', label: 'M' },
      { option: 'l', label: 'L' },
      { option: 'xl', label: 'XL' },
      { option: 'xxl', label: 'XXL' },
      { option: 'xs34', label: 'XS/34' },
      { option: 's36', label: 'S/36' },
      { option: 'm38', label: 'M/38' },
      { option: 'l40', label: 'L/40' },
      { option: 'xl42', label: 'XL/42' },
      { option: 'xxl44', label: 'XXL/44+' },
      //{ option: 'freeSize', label: 'Free Size' },
      //'XS/34', 'S/36', 'M/38', 'L/40', 'XL/42', 'XXL/44+', 'Free Size'
      { option: 'xs32', label: 'XS/32' },
      { option: 's34', label: 'S/34' },
      { option: 'm36', label: 'M/36' },
      { option: 'l38', label: 'L/38' },
      { option: 'xl40', label: 'XL/40' },
      { option: 'xxl42', label: 'XXL/42+' },

      { option: 'freeSize', label: 'Free Size' },
      //'XS/32', 'S/34', 'M/36', 'L/38', 'XL/40', 'XXL/42+', 'Free Size'
    ],
    filterConfig: {
      filterType: 'SelectMultipleChipFilter',
      layoutType: 'row',
      indexForSearch: true,
      label: 'Size',
      group: 'primary',
    },
    showConfig: {
      label: 'Size',
      isDetail: true,
    },
    saveConfig: {
      label: 'Size',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a size',
    },
  },

  {
    key: 'designer',
    scope: 'public',
    includeForListingTypes: ['product-selling'],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'Sabyasachi', label: 'Sabyasachi' },
      { option: 'Seema Gujral', label: 'Seema Gujral' },
      { option: 'Ritu Kumar', label: 'Ritu Kumar' },
      { option: 'Papa Don\'t Preach', label: 'Papa Don\'t Preach' },
      { option: 'Kynah', label: 'Kynah' },
      { option: 'Pernia\'s', label: 'Pernia\'s' },
      { option: 'Manish Malhotra', label: 'Manish Malhotra' },
      { option: 'Anju Modi', label: 'Anju Modi' },
      { option: 'Anushree Reddy', label: 'Anushree Reddy' },
      { option: 'Paulmi & Harsh', label: 'Paulmi & Harsh' },
      { option: 'Seeaash', label: 'Seeaash' },
    ]
  },

  {
    key: 'composition',
    scope: 'public',
    includeForListingTypes: ['product-selling'],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'white_gold', label: 'White gold' },
      { option: 'rayon', label: 'Rayon' },
      { option: 'alpaca', label: 'Alpaca' },
      { option: 'organic_cotton', label: 'Organic cotton' },
      { option: 'velvet', label: 'Velvet' },
      { option: 'transparent_acrylic', label: 'Transparent acrylic' },
      { option: 'leather', label: 'Leather' },
      { option: 'mushroom_leather', label: 'Mushroom leather' },
      { option: 'rose_gold', label: 'Rose gold' },
      { option: 'suede', label: 'Suede' },
      { option: 'rattan', label: 'Rattan' },
      { option: 'angora', label: 'Angora' },
      { option: 'straw', label: 'Straw' },
      { option: 'gold', label: 'Gold' },
      { option: 'hemp', label: 'Hemp' },
      { option: 'wool_blend', label: 'Wool blend' },
      { option: 'vegan_leather', label: 'Vegan leather' },
      { option: 'acetate', label: 'Acetate' },
      { option: 'denim_jeans', label: 'Denim jeans' },
      { option: 'vacuna', label: 'Vacuna' },
      { option: 'rubberized_fabric', label: 'Rubberized fabric' },
      { option: 'rose_gold_plated', label: 'Rose gold plated' },
      { option: 'glitter', label: 'Glitter' },
      { option: 'gold_plated', label: 'Gold plated' },
      { option: 'wood', label: 'Wood' },
      { option: 'neoprene', label: 'Neoprene' },
      { option: 'raffia', label: 'Raffia' },
      { option: 'resin', label: 'Resin' },
      { option: 'tweed', label: 'Tweed' },
      { option: 'viscose', label: 'Viscose' },
      { option: 'polyamide', label: 'Polyamide' },
      { option: 'ostrich_leather', label: 'Ostrich leather' },
      { option: 'recycled_materials', label: 'Recycled materials' },
      { option: 'cupro', label: 'Cupro' },
      { option: 'pink_gold', label: 'Pink gold' },
      { option: 'silver_plated', label: 'Silver plated' },
      { option: 'other_sustainable_material', label: 'Other sustainable material' },
      { option: 'exotic_leathers', label: 'Exotic leathers' },
      { option: 'suede_leather', label: 'Suede leather' },
      { option: 'yellow_gold', label: 'Yellow gold' },
      { option: 'woven_straw', label: 'Woven straw' },
      { option: 'cotton', label: 'Cotton' },
      { option: 'paper', label: 'Paper' },
      { option: 'jade', label: 'Jade' },
      { option: 'metal', label: 'Metal' },
      { option: 'pvc', label: 'Pvc' },
      { option: 'canvas', label: 'Canvas' },
      { option: 'silver', label: 'Silver' },
      { option: 'denim', label: 'Denim' },
      { option: 'sterling_silver', label: 'Sterling silver' },
      { option: 'jute', label: 'Jute' },
      { option: 'satin', label: 'Satin' },
      { option: 'recycled_material', label: 'Recycled material' },
      { option: 'cork', label: 'Cork' },
      { option: 'silk', label: 'Silk' },
      { option: 'gemstones', label: 'Gemstones' },
      { option: 'patent_leather', label: 'Patent leather' },
      { option: 'sequins', label: 'Sequins' },
      { option: 'camel', label: 'Camel' },
      { option: 'rhodium_plated', label: 'Rhodium plated' },
      { option: 'cloth', label: 'Cloth' },
      { option: 'wool', label: 'Wool' },
      { option: 'cashmere', label: 'Cashmere' },
      { option: 'acetate_bio', label: 'Acetate bio' },
      { option: 'vinyl_coated_materials', label: 'Vinyl coated materials' },
      { option: 'recycled_polyester', label: 'Recycled polyester' },
      { option: 'wicker', label: 'Wicker' },
      { option: 'other_material', label: 'Other material' },
      { option: 'polyester', label: 'Polyester' },
      { option: 'crocodile_leather', label: 'Crocodile leather' },
      { option: 'stainless_steel', label: 'Stainless steel' },
      { option: 'silver_gilt', label: 'Silver gilt' },
      { option: 'nattle', label: 'Nattle' },
      { option: 'vinyl', label: 'Vinyl' },
      { option: 'linen', label: 'Linen' },
      { option: 'chains', label: 'Chains' },
      { option: 'platinum', label: 'Platinum' },
      { option: 'spandex', label: 'Spandex' },
      { option: 'faux_leather', label: 'Faux leather' },
      { option: 'synthetic', label: 'Synthetic' },
      { option: 'seaweed', label: 'Seaweed' },
      { option: 'steel', label: 'Steel' },
      { option: 'plastic', label: 'Plastic' },
      { option: 'faux_fur', label: 'Faux fur' },
      { option: 'tencel', label: 'Tencel' },
      { option: 'bamboo', label: 'Bamboo' },
      { option: 'titanium', label: 'Titanium' },
      { option: 'snakeskin', label: 'Snakeskin' },
      { option: 'fur', label: 'Fur' },
      { option: 'rubber', label: 'Rubber' },
      { option: 'ceramic', label: 'Ceramic' },
      { option: 'other', label: 'Other' },
      { option: 'lace', label: 'Lace' },
      { option: 'gold_and_steel', label: 'Gold and steel' },
      { option: 'acryl', label: 'Acryl' },
    ],
    filterConfig: {
      filterType: 'SelectMultipleFilterWithSearch',
      indexForSearch: true,
      label: 'Composition',
      group: 'primary',
    },
    showConfig: {
      label: 'Composition',
      isDetail: true,
    },
    saveConfig: {
      label: 'Composition',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a composition',
    },
  },

  {
    key: 'neckLine',
    scope: 'public',
    includeForListingTypes: ['product-selling'],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'round', label: 'Round' },
      { option: 'vNeck', label: 'V-Neck' },
      { option: 'assymetric', label: 'Assymetric' },
      { option: 'boys', label: 'Boys' },
    ],
    filterConfig: {
      filterType: 'SelectMultipleChipFilter',
      layoutType: 'column',
      indexForSearch: true,
      label: 'Neck-line',
      group: 'primary',
    },
    showConfig: {
      label: 'Neck-line',
      isDetail: true,
    },
    saveConfig: {
      label: 'Neck-line',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a size',
    },
  },

  {
    key: 'sleeves',
    scope: 'public',
    includeForListingTypes: ['product-selling'],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'noSleeves', label: 'No sleeves' },
      { option: 'short', label: 'Short' },
      { option: 'mid', label: 'Mid' },
      { option: 'boys', label: 'Boys' },
    ],
    filterConfig: {
      filterType: 'SelectMultipleChipFilter',
      layoutType: 'column',
      indexForSearch: true,
      label: 'Sleeves',
      group: 'primary',
    },
    showConfig: {
      label: 'Sleeves',
      isDetail: true,
    },
    saveConfig: {
      label: 'Sleeves',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a size',
    },
  },

  {
    key: 'cut',
    scope: 'public',
    includeForListingTypes: ['product-selling'],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'straight', label: 'Straight' },
      { option: 'flared', label: 'Flared' },
      { option: 'aLine', label: 'A-Line' },
      { option: 'oversized', label: 'Oversized' },
      { option: 'tight', label: 'Tight' },
      { option: 'other', label: 'Other' },
    ],
    filterConfig: {
      filterType: 'SelectMultipleChipFilter',
      layoutType: 'column',
      indexForSearch: true,
      label: 'Cut',
      group: 'primary',
    },
    showConfig: {
      label: 'Cut',
      isDetail: true,
    },
    saveConfig: {
      label: 'Cut',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a size',
    },
  },

  {
    key: 'color',
    scope: 'public',
    includeForListingTypes: ['product-selling'],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'white', label: 'White', color: '#ffffff' },
      { option: 'ecru', label: 'Ecru', color: '#f3efe0' },
      { option: 'black', label: 'Black', color: '#000000' },
      { option: 'grey', label: 'Grey', color: '#808080' },
      { option: 'nude', label: 'Nude', color: '#e3bc9a' },
      { option: 'blue', label: 'Blue', color: '#0000ff' },
      { option: 'green', label: 'Green', color: '#008000' },
      { option: 'yellow', label: 'Yellow', color: '#ffff00' },
      { option: 'orange', label: 'Orange', color: '#ffa500' },
      { option: 'red', label: 'Red', color: '#ff0000' },
      { option: 'pink', label: 'Pink', color: '#ffc0cb' },
      { option: 'purple', label: 'Purple', color: '#800080' },
      { option: 'brown', label: 'Brown', color: '#964b00' },
      { option: 'metallic', label: 'Metallic', color: '#aaa9ad' },
      { option: 'gold', label: 'Gold', color: '#ffd700' },
      { option: 'silver', label: 'Silver', color: '#c0c0c0' },
      { option: 'bronze', label: 'Bronze', color: '#cd7f32' },
      { option: 'roseGold', label: 'Rose Gold', color: '#b76e79' },
    ],
    filterConfig: {
      filterType: 'SelectMultipleChipFilter',
      layoutType: 'grid',
      indexForSearch: true,
      label: 'Color',
      group: 'primary',
    },
    showConfig: {
      label: 'Color',
      isDetail: true,
    },
    saveConfig: {
      label: 'Color',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a size',
    },
  },

  {
    key: 'pattern',
    scope: 'public',
    includeForListingTypes: ['product-selling'],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'plain', label: 'Plain' },
      { option: 'abstract', label: 'Abstract' },
      { option: 'animal', label: 'Animal' },
      { option: 'check', label: 'Check' },
      { option: 'crocodile', label: 'Crocodile' },
      { option: 'floral', label: 'Floral' },
      { option: 'houndstooth', label: 'Houndstooth' },
      { option: 'leopard', label: 'Leopard' },
      { option: 'polkadot', label: 'Polkadot' },
      { option: 'snakeSkin', label: 'Snake skin' },
      { option: 'stripped', label: 'Stripped' },
      { option: 'tartan', label: 'Tartan' },
      { option: 'zebra', label: 'Zebra' },
      { option: 'jacquard', label: 'Jacquard' },
      { option: 'logo', label: 'Logo' },
      { option: 'other', label: 'Other' },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Pattern',
      group: 'primary',
    },
    showConfig: {
      label: 'Pattern',
      isDetail: true,
    },
    saveConfig: {
      label: 'Pattern',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a size',
    },
  },

  {
    key: 'occasion',
    scope: 'public',
    includeForListingTypes: ['product-selling'],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'straight', label: 'Straight' },
      { option: 'flared', label: 'Flared' },
      { option: 'aLine', label: 'A-line' },
      { option: 'oversized', label: 'Oversized' },
      { option: 'tight', label: 'Tight' },
      { option: 'other', label: 'Other' },
    ],
    filterConfig: {
      filterType: 'SelectMultipleChipFilter',
      layoutType: 'column',
      indexForSearch: true,
      label: 'Occasion',
      group: 'primary',
    },
    showConfig: {
      label: 'Occasion',
      isDetail: true,
    },
    saveConfig: {
      label: 'Occasion',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a size',
    },
  },

  {
    key: 'season',
    scope: 'public',
    includeForListingTypes: ['product-selling'],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'straight', label: 'Straight' },
      { option: 'flared', label: 'Flared' },
      { option: 'aLine', label: 'A-line' },
      { option: 'oversized', label: 'Oversized' },
      { option: 'tight', label: 'Tight' },
      { option: 'other', label: 'Other' },
    ],
    filterConfig: {
      filterType: 'SelectMultipleChipFilter',
      layoutType: 'column',
      indexForSearch: true,
      label: 'Season',
      group: 'primary',
    },
    showConfig: {
      label: 'Season',
      isDetail: true,
    },
    saveConfig: {
      label: 'Season',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a size',
    },
  },
  // {
  //   key: 'category',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'city-bikes', label: 'City bikes' },
  //     { option: 'electric-bikes', label: 'Electric bikes' },
  //     { option: 'mountain-bikes', label: 'Mountain bikes' },
  //     { option: 'childrens-bikes', label: "Children's bikes" },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectMultipleFilter',
  //     label: 'Category',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Category',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Category',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a category.',
  //   },
  // },
  // {
  //   key: 'tire',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: '29', label: '29' },
  //     { option: '28', label: '28' },
  //     { option: '27', label: '27' },
  //     { option: '26', label: '26' },
  //     { option: '24', label: '24' },
  //     { option: '20', label: '20' },
  //     { option: '18', label: '18' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Tire size',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Tire size',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Tire size',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a tire size.',
  //   },
  // },
  // {
  //   key: 'brand',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'cube', label: 'Cube' },
  //     { option: 'diamant', label: 'Diamant' },
  //     { option: 'ghost', label: 'GHOST' },
  //     { option: 'giant', label: 'Giant' },
  //     { option: 'kalkhoff', label: 'Kalkhoff' },
  //     { option: 'kona', label: 'Kona' },
  //     { option: 'otler', label: 'Otler' },
  //     { option: 'vermont', label: 'Vermont' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Brand',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Brand',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Brand',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a brand.',
  //   },
  // },
  // {
  //   key: 'accessories',
  //   scope: 'public',
  //   schemaType: 'multi-enum',
  //   enumOptions: [
  //     { option: 'bell', label: 'Bell' },
  //     { option: 'lights', label: 'Lights' },
  //     { option: 'lock', label: 'Lock' },
  //     { option: 'mudguard', label: 'Mudguard' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Accessories',
  //     searchMode: 'has_all',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Accessories',
  //   },
  //   saveConfig: {
  //     label: 'Accessories',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: false,
  //   },
  // },

  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: The listingTypes come from listingTypes asset nowadays by default.
//         To use this built-in configuration, you need to change the overwrite from configHelper.js
//         (E.g. use mergeDefaultTypesAndFieldsForDebugging func)
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields These are tied to transaction processes. Different processes have different flags.
 *                        E.g. default-inquiry can toggle price and location to true/false value to indicate,
 *                        whether price (or location) tab should be shown. If defaultListingFields.price is not
 *                        explicitly set to _false_, price will be shown.
 *                        If the location or pickup is not used, listing won't be returned with location search.
 *                        Use keyword search as main search type if location is not enforced.
 *                        The payoutDetails flag allows provider to bypass setting of payout details.
 *                        Note: customers can't order listings, if provider has not set payout details! Monitor
 *                        providers who have not set payout details and contact them to ensure that they add the details.
 */

export const listingTypes = [
  {
    listingType: 'store-listing',
    label: 'Store listing',
    transactionType: {
      process: 'default-store',
      alias: 'default-store/release-1',
      unitType: 'item',
    },
    defaultListingFields: {
      location: false,
      payoutDetails: false,
    },
  },
  {
    listingType: 'brand-listing',
    label: 'Brand listing',
    transactionType: {
      process: 'default-brand',
      alias: 'default-brand/release-1',
      unitType: 'item',
    },
    defaultListingFields: {
      location: false,
      payoutDetails: false,
    },
  },
  {
    listingType: 'daily-booking',
    label: 'Store renting space listing',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'day',
    },
    defaultListingFields: {
      location: false,
      payoutDetails: false,
    },
  },
  {
    listingType: 'product-selling',
    label: 'Sell clothes',
    transactionType: {
      process: 'default-purchase',
      alias: 'default-purchase/release-1',
      unitType: 'item',
    },
    stockType: 'multipleItems',
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  //   defaultListingFields: {
  //     shipping: true,
  //     pickup: true,
  //     payoutDetails: true,
  //   },
  // },
  // {
  //   listingType: 'inquiry',
  //   label: 'Inquiry',
  //   transactionType: {
  //     process: 'default-inquiry',
  //     alias: 'default-inquiry/release-1',
  //     unitType: 'inquiry',
  //   },
  //   defaultListingFields: {
  //     price: false,
  //     location: true,
  //   },
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
