import React, { Component } from 'react';
import { bool, func, number, object, oneOfType, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import Decimal from 'decimal.js';

import { intlShape, injectIntl } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  isSafeNumber,
  unitDivisor,
  convertUnitToSubUnit,
  convertMoneyToNumber,
  ensureDotSeparator,
  ensureSeparator,
  truncateToSubUnitPrecision,
} from '../../util/currency';
import { propTypes } from '../../util/types';
import * as log from '../../util/log';

import { ValidationError } from '../../components';

import css from './FieldCurrencyWithoutDecimalsInput.module.css';

const { Money } = sdkTypes;

const allowedInputProps = allProps => {
  const { currencyConfig, defaultValue, intl, input, meta, ...inputProps } = allProps;
  return inputProps;
};

const getPrice = (unformattedValue, currencyConfig) => {
  const isEmptyString = unformattedValue === '';
  try {
    return isEmptyString
      ? null
      : new Money(
        convertUnitToSubUnit(unformattedValue, unitDivisor(currencyConfig.currency)),
        currencyConfig.currency
      );
  } catch (e) {
    return null;
  }
};

class CurrencyWithoutDecimalsInputComponent extends Component {
  constructor(props) {
    super(props);
    const { currencyConfig, defaultValue, input, intl } = props;
    const initialValueIsMoney = input.value instanceof Money;

    if (initialValueIsMoney && input.value.currency !== currencyConfig.currency) {
      const e = new Error('Value currency different from marketplace currency');
      log.error(e, 'currency-input-invalid-currency', { currencyConfig, inputValue: input.value });
      throw e;
    }

    const initialValue = initialValueIsMoney ? convertMoneyToNumber(input.value) : defaultValue;
    const hasInitialValue = typeof initialValue === 'number' && !isNaN(initialValue);

    try {
      const unformattedValue = hasInitialValue
        ? Math.floor(initialValue).toString()
        : '';
      const formattedValue = hasInitialValue
        ? intl.formatNumber(unformattedValue, { ...currencyConfig, minimumFractionDigits: 0, maximumFractionDigits: 0 })
        : '';

      this.state = {
        formattedValue,
        unformattedValue,
        value: formattedValue,
      };
    } catch (e) {
      log.error(e, 'currency-input-init-failed', { currencyConfig, defaultValue, initialValue });
      throw e;
    }

    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.updateValues = this.updateValues.bind(this);
  }

  onInputChange(event) {
    event.preventDefault();
    event.stopPropagation();
    const { unformattedValue } = this.updateValues(event);
    const price = getPrice(unformattedValue, this.props.currencyConfig);
    this.props.input.onChange(price);
  }

  onInputBlur(event) {
    event.preventDefault();
    event.stopPropagation();
    const { currencyConfig, input: { onBlur } } = this.props;
    this.setState(prevState => {
      if (onBlur) {
        const price = getPrice(prevState.unformattedValue, currencyConfig);
        onBlur(price);
      }
      return {
        value: prevState.formattedValue,
      };
    });
  }

  onInputFocus(event) {
    event.preventDefault();
    event.stopPropagation();
    const { currencyConfig, input: { onFocus } } = this.props;
    this.setState(prevState => {
      if (onFocus) {
        const price = getPrice(prevState.unformattedValue, currencyConfig);
        onFocus(price);
      }
      return {
        value: prevState.unformattedValue,
      };
    });
  }

  updateValues(event) {
    try {
      const { currencyConfig, intl } = this.props;
      const targetValue = event.target.value.trim();
      const isEmptyString = targetValue === '';

      if (!/^\d*$/.test(targetValue)) {
        throw new Error(`Invalid input value: ${targetValue}`);
      }

      const truncatedValueString = targetValue;
      const unformattedValue = !isEmptyString ? truncatedValueString : '';
      const formattedValue = !isEmptyString
        ? intl.formatNumber(truncatedValueString, { ...currencyConfig, minimumFractionDigits: 0, maximumFractionDigits: 0 })
        : '';

      this.setState({
        formattedValue,
        value: unformattedValue,
        unformattedValue,
      });

      return { formattedValue, value: unformattedValue, unformattedValue };
    } catch (e) {
      console.warn('Not a valid value.', e);

      const { formattedValue, unformattedValue, value } = this.state;
      return { formattedValue, unformattedValue, value };
    }
  }

  render() {
    const { className, currencyConfig, defaultValue, placeholder, intl } = this.props;
    const placeholderText = placeholder || intl.formatNumber(defaultValue, { ...currencyConfig, minimumFractionDigits: 0, maximumFractionDigits: 0 });
    return (
      <input
        className={className}
        {...allowedInputProps(this.props)}
        value={this.state.value}
        onChange={this.onInputChange}
        onBlur={this.onInputBlur}
        onFocus={this.onInputFocus}
        type="text"
        inputMode="numeric"
        placeholder={placeholderText}
      />
    );
  }
}

CurrencyWithoutDecimalsInputComponent.defaultProps = {
  className: null,
  defaultValue: null,
  input: null,
  placeholder: null,
};

CurrencyWithoutDecimalsInputComponent.propTypes = {
  className: string,
  currencyConfig: propTypes.currencyConfig.isRequired,
  defaultValue: number,
  intl: intlShape.isRequired,
  input: shape({
    value: oneOfType([string, propTypes.money]),
    onBlur: func,
    onChange: func.isRequired,
    onFocus: func,
  }).isRequired,

  placeholder: string,
};

export const CurrencyWithoutDecimalsInput = injectIntl(CurrencyWithoutDecimalsInputComponent);

const FieldCurrencyWithoutDecimalsInputComponent = props => {
  const { rootClassName, className, id, label, input, meta, hideErrorMessage, displayColumn, ...rest } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;

  const hasError = touched && invalid && error;

  const inputClasses = classNames(css.input, {
    [css.inputSuccess]: valid,
    [css.inputError]: hasError,
  });

  const inputProps = { className: inputClasses, id, input, ...rest };
  const classes = classNames(rootClassName, className);
  return (
    <div className={classes}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <div style={displayColumn ? { display: 'flex', flexDirection: 'column' } : { display: 'flex', flexDirection: 'row' }}>
        <CurrencyWithoutDecimalsInput {...inputProps} />
        {hideErrorMessage ? null : <ValidationError fieldMeta={meta} />}
      </div>
    </div>
  );
};

FieldCurrencyWithoutDecimalsInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  id: null,
  label: null,
  hideErrorMessage: false,
};

FieldCurrencyWithoutDecimalsInputComponent.propTypes = {
  rootClassName: string,
  className: string,

  id: string,
  label: string,
  hideErrorMessage: bool,

  input: object.isRequired,
  meta: object.isRequired,
};

const FieldCurrencyWithoutDecimalsInput = props => {
  return <Field component={FieldCurrencyWithoutDecimalsInputComponent} {...props} />;
};

export default FieldCurrencyWithoutDecimalsInput;
