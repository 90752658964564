import React from 'react';
import { node, string } from 'prop-types';
import { Field } from 'react-final-form';

import css from './FieldChip.module.css';

const FieldChipComponent = props => {
  const {
    inactiveClassName,
    activeClassName,
    id,
    label,
    isHovered,
    ...rest
  } = props;


  // This is a workaround for a bug in Firefox & React Final Form.
  // https://github.com/final-form/react-final-form/issues/134
  const handleOnChange = (input, event) => {
    const { onBlur, onChange } = input;
    onChange(event);
    onBlur(event);

    // If onChange has been passed as a props to FieldCheckbox
    if (rest.onChange) {
      rest.onChange(event);
    }
  };

  return (
    <span className={css.root}>
      <Field type="checkbox" {...rest}>
        {props => {
          const { input, disabled } = props;
          const isSelected = props.input.checked;

          return (
            <div className={isHovered || isSelected ? activeClassName : inactiveClassName}>
              <input
                id={id}
                className={css.input}
                {...input}
                onChange={event => handleOnChange(input, event)}
                disabled={disabled}
              />
              <label htmlFor={id}>
                {label}
              </label>
            </div>
          );
        }}
      </Field>
    </span>
  );
};

FieldChipComponent.defaultProps = {
  label: null,
};

FieldChipComponent.propTypes = {
  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldChipComponent;
